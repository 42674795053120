define("discourse/plugins/discourse-chat-integration/admin/models/channel", ["exports", "discourse/models/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Channel extends _rest.default {
    updateProperties() {
      return this.getProperties(["data"]);
    }
    createProperties() {
      return this.getProperties(["provider", "data"]);
    }
  }
  _exports.default = Channel;
});